import dayjs from 'dayjs'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { createBlurUp } from '@mux/blurup'
import { DDLogger } from '@root/utils'
import { useUIStore } from '@root/store'
import { contentGenApi } from '@root/api'
import { IContentCardData } from '@root/types/ContentGen'
import { labels } from '../labels'
import { ApiError } from '../errors'
import { DiscoverHelper } from '../helpers'
import socialMediaApi, { SocialMediaRequest } from '../api/socialMediaApi'

type DialogOptions = {
  title: string
  subtitle?: string
  titleSize?: number
  textAlign?: 'text-left' | 'text-center' | 'text-right'
  titleAlign?: 'text-left' | 'text-center' | 'text-right'
  buttonLabel?: string
  declineButtonLabel?: string
  confirm?: () => Promise<void>
  decline?: () => Promise<void>
}

type HeartedContentValue = {
  updatedAt: number
  reaction: string
  heartCount: number
}
interface TodayContentInitialState {
  showErrorDialog: boolean
  showWarningDialog: boolean
  warningDialogOptions?: DialogOptions
  errorDialogOptions?: DialogOptions
  todayContent: IContentCardData[]
  loading: boolean
  error: string | null
  activePlaybackId?: string
  isMuted: boolean
  totalCardInteraction: number
  connectionType: string
  blurVideos: Record<string, { blurDataURL: string; aspectRatio: number }>
  contentIds: string[]
  reactionContentIds: Record<string, HeartedContentValue>
}

type TodayContentCUDResult = { success: boolean; error?: ApiError }

interface TodayContentState extends TodayContentInitialState {
  getTodayContent: () => Promise<void>
  setActivePlaybackId: (playbackId: string) => void
  setIsMuted: VoidFunction
  setShowErrorDialog: (value: boolean, options?: DialogOptions) => void
  setShowWarningDialog: (value: boolean, options?: DialogOptions) => void
  updateTotalCardIteration: VoidFunction
  saveReaction: (
    params: SocialMediaRequest.SaveReactionParams & { heartCount: number }
  ) => Promise<TodayContentCUDResult>
  reset: VoidFunction
}

const defaultState: TodayContentInitialState = {
  todayContent: [],
  contentIds: [],
  loading: true,
  isMuted: true,
  showErrorDialog: false,
  error: null,
  blurVideos: {},
  reactionContentIds: {},
  connectionType: 'unknown',
  showWarningDialog: false,
  totalCardInteraction: 0,
}

const DEFAULT_ASPECT_RATIO = 9 / 16
const useDiscoverStore = create<TodayContentState>()(
  persist(
    (set, get) => ({
      ...defaultState,
      setShowErrorDialog: (value, options = undefined) => {
        set({ errorDialogOptions: options, showErrorDialog: value })
      },
      setShowWarningDialog: (value, options = undefined) => {
        set({ warningDialogOptions: options, showWarningDialog: value })
      },
      getTodayContent: async () => {
        set({ loading: true, error: null })
        try {
          set({
            connectionType:
              navigator?.connection?.effectiveType ??
              (await DiscoverHelper.measureNetworkEffectiveType()),
          })

          const { data } = await contentGenApi.fetchTodayTabContent({
            layoutGroupCategory: 'DISCOVER_WEB',
          })

          const now = Date.now()
          const contentIds: string[] = []
          const playbackIds: string[] = []
          const reactionContentIds = { ...get().reactionContentIds }
          const todayContent = (data.layoutsAndContents ?? [])
            .flatMap((layout) => layout.contents ?? [])
            .filter((content) => {
              if (content.type === 'VerticalCard') {
                contentIds.push(content.id)
                const playbackId =
                  content.properties?.videoProperties?.playbackId
                if (playbackId) {
                  playbackIds.push(playbackId)
                }
                let reactionContent = reactionContentIds[content.id]
                if (reactionContent?.updatedAt) {
                  const updatedAt = dayjs(reactionContent.updatedAt)
                  const twoHoursAgo = dayjs().subtract(2.1, 'hour') // Get the time 2 hours ago and 6 minutes ago.
                  // The content was updated more than 2 hours ago.
                  if (updatedAt.isBefore(twoHoursAgo)) {
                    reactionContent = {
                      updatedAt: now,
                      reaction: content.properties.reaction,
                      heartCount: content.properties.heartCount,
                    }
                    reactionContentIds[content.id] = reactionContent
                  }
                }

                return true
              }
              return false
            })

          const blurVideos: TodayContentInitialState['blurVideos'] = {}

          await Promise.allSettled(
            playbackIds.map(async (playbackId) => {
              try {
                const { blurDataURL = '', aspectRatio = DEFAULT_ASPECT_RATIO } =
                  await createBlurUp(playbackId, { blur: 20 })
                blurVideos[playbackId] = { blurDataURL, aspectRatio }
              } catch (error) {
                DDLogger.error(
                  `Failed to create blur up for playback ID ${playbackId}:`,
                  error
                )
                blurVideos[playbackId] = {
                  blurDataURL: '',
                  aspectRatio: DEFAULT_ASPECT_RATIO,
                }
              }
            })
          )

          set({
            todayContent,
            loading: false,
            blurVideos,
            contentIds,
            reactionContentIds,
          })
        } catch (error) {
          let errorMessage = 'Something went wrong'
          if (error instanceof Error) {
            errorMessage = error.message
          }

          set({ error: errorMessage, loading: false })
        }
      },
      setActivePlaybackId: (activePlaybackId) => {
        set({ activePlaybackId })
      },
      setIsMuted: () => {
        set(({ isMuted }) => ({
          isMuted: !isMuted,
        }))
      },
      updateTotalCardIteration: () => {
        set(({ totalCardInteraction }) => ({
          totalCardInteraction: ++totalCardInteraction,
        }))
      },
      saveReaction: async ({
        contentId,
        reaction,
        heartCount,
        contentSource,
      }) => {
        const { setShowNotificationModal } = useUIStore.getState()
        try {
          await socialMediaApi.saveReaction({
            contentId,
            reaction,
            contentSource,
          })
          const reactionContentIds = { ...get().reactionContentIds }
          reactionContentIds[contentId] = {
            heartCount,
            reaction,
            updatedAt: Date.now(),
          }
          set({ reactionContentIds })
          return {
            success: true,
          }
        } catch (error) {
          setShowNotificationModal(true, {
            text: labels.errors.unknown.description,
            autoHideDuration: 3000,
          })
          return {
            success: false,
            error: new ApiError({
              ...labels.errors.unknown,
              name: 'DeleteComment',
              code: 'unknown',
            }),
          }
        }
      },
      reset: () => {
        set(defaultState)
      },
    }),
    {
      partialize: (state) => ({
        reactionContentIds: state.reactionContentIds,
      }),
      name: 'contents-reaction',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export default useDiscoverStore
