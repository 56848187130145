import { ENV } from '@root/config'

function getContentfulUrlHandler(url: string): { [key: string]: string } {
  const decodedParameters: { [key: string]: string } = {}

  try {
    const queryString = url.includes('?') ? url.split('?')[1] : ''
    const urlParameters = new URLSearchParams(queryString)
    urlParameters.forEach((value, key) => {
      decodedParameters[key] = decodeURIComponent(value)
    })
  } catch (_e) {}

  return decodedParameters
}

const handleClickCtaAction = (ctaHandler?: string) => {
  if (ctaHandler) {
    const { uri } = DiscoverHelper.getContentfulUrlHandler(ctaHandler)
    if (!!uri) {
      window.open(uri, '_blank')
    } else {
      window.open(ENV.ONE_LINKS.default, '_blank')
    }
  }
}

const calculateSpeed = (durationMs: number, assetSizeBytes: number): number => {
  const durationSec = durationMs / 1000 // Convert milliseconds to seconds
  // Calculate speed in kilobits per second (kbps)
  const speedKbps = (assetSizeBytes * 8) / (durationSec * 1024)
  return speedKbps
}

const MINIMUM_KBPS = 100
const ASSET_SIZE_BYTES = 15086
const ASSET_URL =
  'https://assets.ctfassets.net/38eowqxxlfkc/WtBRcacrYrsSirLJnBX9e/3a1ef15432ea4db5043f767c38721f9d/favicon.png'

const measureNetworkSpeed = (
  callback: (durationMs: number, assetSizeBytes: number) => void
) => {
  const image = new Image()
  let startTime = 0,
    endTime = 0

  image.onload = () => {
    endTime = performance.now()
    const durationMs = endTime - startTime // Duration in milliseconds
    callback(durationMs, ASSET_SIZE_BYTES)
  }

  image.onerror = () => {
    callback(-1, ASSET_SIZE_BYTES)
  }

  startTime = performance.now()
  // Prevent caching by appending a unique query parameter to the URL
  image.src = `${ASSET_URL}?cache_buster=${Date.now()}`
}

const measureNetworkEffectiveType = () =>
  new Promise<'4g' | 'unknown' | 'slow'>((resolve) => {
    measureNetworkSpeed((durationMs, assetSizeBytes) => {
      if (durationMs !== -1) {
        const speedKbps = calculateSpeed(durationMs, assetSizeBytes)
        resolve(speedKbps > MINIMUM_KBPS ? '4g' : 'slow')
      } else {
        resolve('unknown')
      }
    })
  })

const getMaxResolution = (connectionType: string) => {
  switch (connectionType) {
    case '4g':
      return '1440p'
    case 'unknown':
      return '1080p'
    default:
      return '720p'
  }
}

export interface TextPart {
  text: string
  linkUrl?: string
}

export const splitTextLinks = (text: string): TextPart[] => {
  const linkRegex = /<link href="(.+?)">(.*?)<\/link>/g
  const parts: TextPart[] = []
  let lastIndex = 0

  let match
  while ((match = linkRegex.exec(text)) !== null) {
    const [fullMatch, linkUrl, linkText] = match
    const beforeLink = text.slice(lastIndex, match.index)

    if (beforeLink) {
      parts.push({ text: beforeLink })
    }

    parts.push({ text: linkText, linkUrl })

    lastIndex = match.index + fullMatch.length
  }

  if (lastIndex < text.length) {
    parts.push({ text: text.slice(lastIndex) })
  }

  return parts
}

export const DiscoverHelper = {
  splitTextLinks,
  getMaxResolution,
  handleClickCtaAction,
  getContentfulUrlHandler,
  measureNetworkEffectiveType,
}
