import {
  IRecentCashAdvanceData,
  IRepaymentMethod,
  ICreateAppAndLinkEsignData,
  IRepaymentFundOptionsData,
  IDisbursementAmountsData,
  IGetTipRequest,
  IGetDisbursementMethodRequest,
  IDisbursementMethodData,
  IPostApplyInstacashDisbursementRequest,
  IApplyInstacashDisbursementData,
  IV2TipsData,
  OnboardingQualificationResponse,
  IInstacashTncEsignDocuments,
  IInstacashTncEsignDocument,
  IInstacashEligibilityBVAccount,
  IInstacashTimeZone,
  IOutstandingCashAdvance,
  IRepaymentFundOptionFund,
  ExperimentTierEligibilityExplanation,
  IInstacashTargetBVOptions,
  IInstacashUpdateTargetBVAccountReq,
} from '@root/types/CashAdvance'
import ICashAdvanceInfo from '@root/types/CashAdvanceInfo'
import { generateQueryString, https, httpsClient } from '@root/utils'

export interface IGetCashAdvanceInfoResponse {
  code?: string
  data: ICashAdvanceInfo
  message?: string
}

interface IGetRecentCashAdvanceResponse {
  code: string
  data: IRecentCashAdvanceData
  message: string
}

interface IGetRepaymentMethodResponse {
  code: string
  data: IRepaymentMethod[]
  message: string
}

interface ICreateAppAndLinkEsignResponse {
  code: string
  data: ICreateAppAndLinkEsignData
  message: string
}

interface IGetRepaymentFundOptionsResponse {
  code: string
  data: IRepaymentFundOptionsData
  message: string
}

interface IGetDisbursementAmountsResponse {
  code: string
  data: IDisbursementAmountsData
  message: string
}

interface IGetV2TipsResponse {
  code: string
  data: IV2TipsData
  message: string
}

interface IGetDisbursementMethodResponse {
  code: string
  data: IDisbursementMethodData
  message: string
}

interface IPostApplyInstacashDisbursementResponse {
  code: string
  data: IApplyInstacashDisbursementData
  message: string
}

interface IGetInstacashTncEsignDocumentsResponse {
  code: string
  data: IInstacashTncEsignDocuments
  message: string
}

interface IPostInstacashTncEsignDocumentsResponse {
  code: string
  data: IInstacashTncEsignDocuments
  message: string
}

interface IPutInstacashTncEsignDocumentsResponse {
  code: string
  data: IInstacashTncEsignDocuments
  message: string
}

interface IGetEligibilityBVAccountResponse {
  code: string
  data: IInstacashEligibilityBVAccount
  message: string
}

interface IPutEligibilityBVAccountResponse {
  code: string
  data: IInstacashEligibilityBVAccount
  message: string
}

interface IGetTargetBVOptionsResponse {
  code?: string
  data: IInstacashTargetBVOptions
  message?: string
}

interface IGetUserTimeZoneResponse {
  code: string
  data: IInstacashTimeZone
  message: string
}

export interface IGetOutstandingCashAdvancesResponse {
  code: string
  data: IOutstandingCashAdvance
  message: string
}

interface IPostRepaymentResponse {
  code: string
  data: {
    paidAmount: number
    paymentStatus: string
  }
  message: string
}

interface IEligibilityBankAccountInfo {
  /**
   * At least one of the two object properties below will be null.
   * If the user is DIRECT_DEPOSIT_TIER, then targetBVAccount wil be null.
   * If the user is not DIRECT_DEPOSIT_TIER and has an available TBV account, roarMoney will be null.
   * If the user is not DIRECT_DEPOSIT_TIER and does not have available TBV account, both targetBVAccount and roarMoney will be null.
   */
  targetBVAccount: {
    bankName: string
    last4Digits: string
    qualifyingIncomeDetected: boolean
    bankLogo: string
  } | null
  roarMoney: {
    last4Digits: string
    hasRegularPayFrequency: boolean
  } | null
}

interface IEligibilityCoreAccessRules {
  hasOverdueLoan: boolean
  hasOutstandingCashAdvance: boolean
}

interface IEligibilityBoostInfo {
  title: string
  endDate?: string
  amount: number
}

export interface CAEligibilityExplanationResponseData {
  tier:
    | 'CORE_TIER'
    | 'CREDIT_BUILDER_PLUS_TIER'
    | 'DIRECT_DEPOSIT_TIER'
    | 'LITE_BV_TIER'
    | 'LITE_TIER'
    | ''
  baseWithHiddenAmount: number | null
  nonHiddenBoostAmount: number | null
  unlockedAmount: number | null
  bankAccountInfo: IEligibilityBankAccountInfo | null
  coreAccessRules: IEligibilityCoreAccessRules | null
  boosts: IEligibilityBoostInfo[] | null
}

export interface CAEligibilityExplanationResponse {
  code: string
  data: CAEligibilityExplanationResponseData
  message: string
}

export interface CAExperimentEligibilityExplanationResponse {
  code: string
  data: ExperimentTierEligibilityExplanation
  message: string
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: To get the recent cash advance transactions. If no transactions found, means there is no Instacash disbursement.
 * @returns {IRecentCashAdvanceData}: Returns the recent cash advance transactions
 */
const getRecentCashAdvance = async (
  pageNumber = 0,
  pageSize = 5,
  includeApplication = true,
  includePendingPayment = true,
  filterType: 'cashadvance' | 'payment' | 'application' | '' = '',
  filterSuccess = 'success'
): Promise<IGetRecentCashAdvanceResponse> => {
  const queryString = generateQueryString({
    includeApplication,
    includePendingPayment,
    filterSuccess,
    pageNumber,
    pageSize,
    filterType,
  })

  const response = await (
    await https({
      errorTitle: '[getRecentCashAdvance]',
    })
  ).get(`/cash-advance/recent?${queryString}`)
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: To get a list of Debit Cards that are linked to Instacash Repayment. If no cards found, means there is no debit card link to Instacash Repayment.
 * @returns {IRepaymentMethod[]}: Returns the recent cash advance transactions
 */
const getRepaymentMethods = async (): Promise<IGetRepaymentMethodResponse> => {
  const response = await (
    await https({ errorTitle: '[getRepaymentMethods]' })
  ).get(`/cash-advance/repayment/repayment-methods`)
  return response.data
}

/**
 * @usage:
 *  1) For displaying the Instacash info
 *  2) For the Instacash Flow
 *  3) For the Instacash Disbursement Flow
 * @description:
 *  1) To get the Cash Advance info
 *  2) To check if the user is eligible to enter a flow
 * @returns {ICashAdvanceInfo}: Returns the recent cash advance transactions
 */
const getCashAdvanceInfo = async (): Promise<IGetCashAdvanceInfoResponse> => {
  const response = await (
    await https({ errorTitle: '[getCashAdvanceInfo]' })
  ).get('/cash-advance/info')
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Create Instacash application and link the esign documents
 * @returns {ICreateAppAndLinkEsignData}: Returns the application id and esign documents
 */
const postCreateAppAndLinkEsign = async (
  signal?: AbortSignal
): Promise<ICreateAppAndLinkEsignResponse> => {
  const response = await (
    await https({ signal, errorTitle: '[postCreateAppAndLinkEsign]' })
  ).post('/cash-advance/createAppEsign')
  return response.data
}

/**
 * @usage: For the Add Card Flow
 * @description: Step 2 - Get repayment fund options
 * @returns {IGetRepaymentFundOptionsData}: Returns the repayment fund options
 */
const getRepaymentFundOptions = async (
  types?: string
): Promise<IGetRepaymentFundOptionsResponse> => {
  const urlParams = types ? `?types=${types}` : ''

  const response = await (
    await https({
      errorTitle: '[getRepaymentFundOptions]',
    })
  ).get(`/cash-advance/repayment/fundOptions${urlParams}`)
  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Get a list of disbursement amounts
 * @returns {IDisbursementAmountsData}: Returns a list of disbursement amounts
 */
const getDisbursementAmounts =
  async (): Promise<IGetDisbursementAmountsResponse> => {
    const response = await (
      await https({
        errorTitle: '[getDisbursementAmounts]',
      })
    ).get(`/cash-advance/disbursement/amounts`)
    return response.data
  }

const getV2Tips = async (
  request: IGetTipRequest
): Promise<IGetV2TipsResponse> => {
  const response = await (
    await https({ errorTitle: '[getV2Tips]' })
  ).get(
    `/cash-advance/v2/tips?cashAdvanceAmount=${request.cashAdvanceAmount}&tipSource=${request.tipSource}`
  )
  return response.data
}

/**
 * ! IMPORTANT: This endpoint will fail with error code 400, if the applicationId is undefined
 * @usage: For the Instacash Disbursement Flow
 * @description: Get a list of disbursement methods
 * @param {request}: IGetDisbursementMethodRequest
 * @returns {IDisbursementMethodData}: Returns a list of disbursement methods
 */
const getDisbursementMethod = async (
  request: IGetDisbursementMethodRequest
): Promise<IGetDisbursementMethodResponse> => {
  const { amount, applicationSource, applicationId } = request

  const response = await (
    await https({
      errorTitle: '[getDisbursementMethod]',
    })
  ).get(
    `/cash-advance/disbursement/v3/disbursement-method?applicationAmount=${amount}&applicationSource=${applicationSource}${
      applicationId ? `&applicationId=${applicationId}` : ''
    }`
  )

  return response.data
}

/**
 * @usage: For the Instacash Disbursement Flow
 * @description: Apply for Instacash disbursement
 * @returns {IApplyInstacashDisbursementData}: Returns the disbursement application data
 */
const postApplyInstacashDisbursement = async (
  request: IPostApplyInstacashDisbursementRequest
): Promise<IPostApplyInstacashDisbursementResponse> => {
  const response = await (
    await https({
      expectErrorData: true,
      errorTitle: '[postApplyInstacashDisbursement]',
    })
  ).post(`/cash-advance/v5/apply`, request)
  return response.data
}

/**
 * @description Gets user onboarding qualification info
 * @returns {Promise<OnboardingQualification>}
 */
const getOnboardingQualification =
  async (): Promise<OnboardingQualificationResponse> => {
    const response = await (
      await https({ errorTitle: '[getOnboardingQualification]' })
    ).get(`/cash-advance/onboarding/qualification`)
    return response?.data
  }

/**
 * @usage: For the Instacash Terms and Conditions Esign Flow
 * @description: To fetch the user's Instacash Terms and Conditions Esign documents
 * @returns {IInstacashTncEsignDocuments}: Returns user's Instacash Terms and Conditions Esign documents
 */
const getInstacashTncEsignDocuments =
  async (): Promise<IGetInstacashTncEsignDocumentsResponse> => {
    const response = await (
      await https({ errorTitle: '[getInstacashTncEsignDocuments]' })
    ).get(`/cash-advance/user-esigns`)
    return response.data
  }

/**
 * @usage: For the Instacash Terms and Conditions Esign Flow
 * @description: Generate Instacash Terms and Conditions Esign documents
 * @returns {IInstacashTncEsignDocuments}: Returns generated Instacash Terms and Conditions Esign documents
 */
const postInstacashTncEsignDocuments =
  async (): Promise<IPostInstacashTncEsignDocumentsResponse> => {
    const response = await (
      await https({ errorTitle: '[postInstacashTncEsignDocuments]' })
    ).post('/cash-advance/user-esigns')
    return response.data
  }

/**
 * @usage: For the Instacash Terms and Conditions Esign Flow
 * @description: Update Instacash Terms and Conditions Esign documents
 * @returns {IInstacashTncEsignDocuments}: Returns updated Instacash Terms and Conditions Esign documents
 */
const putInstacashTncEsignDocuments = async ({
  esignDocuments,
}: {
  esignDocuments: IInstacashTncEsignDocument[]
}): Promise<IPutInstacashTncEsignDocumentsResponse> => {
  const response = await (
    await https({ errorTitle: '[putInstacashTncEsignDocuments]' })
  ).put('/cash-advance/user-esigns', { esignDocuments })
  return response.data
}

const getOutstandingCashAdvances =
  async (): Promise<IGetOutstandingCashAdvancesResponse> => {
    const response = await (
      await https({ errorTitle: '[getOutstandingCashAdvances]' })
    ).get(`/cash-advance/outstanding`)
    return response.data
  }

/**
 * @usage:
 *  1) For the Instacash Add Card Flow
 *  2) For Instacash Manage Funds flow
 * @description: To fetch the user's target BV account
 * @returns {IInstacashEligibilityBVAccount}: Returns user's target BV account
 */
const getEligibilityBVAccount =
  async (): Promise<IGetEligibilityBVAccountResponse> => {
    const response = await (
      await https({ errorTitle: '[getEligibilityBVAccount]' })
    ).get(`/cash-advance/eligibility/target-bv-accounts`)
    return response.data
  }

/**
 * @usage: For the Instacash Manage Target BV Accounts page
 * @description: To add or update the user's target BV account
 * @returns {IInstacashEligibilityBVAccount}: Returns user's UPDATED target BV account
 */
const putEligibilityBVAccount = async (
  bvOption: IInstacashTargetBVOptions['bvAccounts'][number]
): Promise<IPutEligibilityBVAccountResponse> => {
  const reqBody: IInstacashUpdateTargetBVAccountReq = {
    bank: {
      accountNumber: null,
      bankName: bvOption.bankName,
    },
    identifier: bvOption.accountLastFour,
    source: 'IC_SELF_SERVICE',
  }

  const response = await httpsClient(
    '/cash-advance/eligibility/target-bv-accounts',
    {
      method: 'PUT',
      body: reqBody,
      errorTitle: '[putEligibilityBVAccount]',
    }
  )

  return response.data
}

/**
 * @usage: For the Instacash Manage Target BV Accounts page
 * @description: To fetch the user's target BV account options
 * @returns {IInstacashTargetBVOptions}: Returns user's target BV account options
 */
const getTargetBVOptions = async (): Promise<IGetTargetBVOptionsResponse> => {
  const response = await (
    await https({ errorTitle: '[getTargetBVOptions]' })
  ).get(`/cash-advance/eligibility/target-bv-account-options`)
  return response.data
}

/**
 * @usage: Used to format the dates that are across all IC modules
 * @description: To fetch the user's time zone info
 * @returns {IInstacashTimeZone}: Returns user's time zone info
 */
const getUserTimeZoneInfo = async (): Promise<IGetUserTimeZoneResponse> => {
  const response = await (
    await https({ errorTitle: '[getUserTimeZoneInfo]' })
  ).get(`/cash-advance/info/time-zone`)
  return response.data
}

/**
 * @usage: For Instacash Manual Repayment Flow
 * @description: To make a IC repayment with RM account
 * @returns {IInstacashDemandDepositRepayment}: Returns paid amount and payment status
 */
const postInstacashDemandDepositRepayment = async ({
  amount,
}: {
  userId: string
  amount: number
}): Promise<IPostRepaymentResponse> => {
  const response = await (
    await https({
      errorTitle: '[postInstacashDemandDepositRepayment]',
    })
  ).post(`/cash-advance/demandDepositAccountPayment`, {
    amount,
  })
  return response.data
}

/**
 * @usage: For Instacash Manual Repayment Flow
 * @description: To make a IC repayment with debit card
 * @returns {IInstacashDebitCardRepayment}: Returns paid amount and payment status
 */
const postInstacashDebitCardRepayment = async ({
  fundId,
  amount,
}: {
  userId: string
  fundId: IRepaymentFundOptionFund['fundId']
  amount: number
}): Promise<IPostRepaymentResponse> => {
  const response = await (
    await https({
      errorTitle: '[postInstacashDebitCardRepayment]',
    })
  ).post(`/cash-advance/debitcardPayment`, {
    amount,
    fundId,
  })
  return response.data
}

/**
 * @usage Instacash eligibility explanation
 * @description Fetches eligibility explanation info
 * @returns {CAEligibilityExplanationResponseData}: Explanation info
 */
const getInstacashEligibilityExplanation =
  async (): Promise<CAEligibilityExplanationResponse> => {
    const response = await httpsClient(
      '/cash-advance/info/tierEligibilityExplanation',
      {
        method: 'GET',
        errorTitle: '[getInstacashEligibilityExplanation]',
      }
    )

    return response.data
  }

/**
 * @usage Instacash experiment tier eligibility explanation
 * @description Fetches eligibility explanation info for experiment tier users
 * @returns {ExperimentTierEligibilityExplanation}: Experiment tier explanation info
 */
const getInstacashExperimentEligibilityInfo =
  async (): Promise<CAExperimentEligibilityExplanationResponse> => {
    const response = await httpsClient(
      '/cash-advance/info/tierEligibilityExplanation/experimentTier',
      {
        method: 'GET',
        errorTitle: '[getInstacashExperimentEligibilityInfo]',
      }
    )

    return response.data
  }

const instacashApi = {
  getCashAdvanceInfo,
  getRecentCashAdvance,
  getRepaymentMethods,
  postCreateAppAndLinkEsign,
  getRepaymentFundOptions,
  getDisbursementAmounts,
  getDisbursementMethod,
  postApplyInstacashDisbursement,
  getV2Tips,
  getOnboardingQualification,
  getInstacashTncEsignDocuments,
  postInstacashTncEsignDocuments,
  putInstacashTncEsignDocuments,
  getOutstandingCashAdvances,
  getEligibilityBVAccount,
  putEligibilityBVAccount,
  getTargetBVOptions,
  getUserTimeZoneInfo,
  postInstacashDemandDepositRepayment,
  postInstacashDebitCardRepayment,
  getInstacashEligibilityExplanation,
  getInstacashExperimentEligibilityInfo,
}

export default instacashApi
