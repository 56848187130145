import { ENV } from '@root/config'

export const labels = {
  errors: {
    unknown: {
      title: 'Oops!',
      description: 'Something went wrong, try again later.',
    },
    spam: {
      title: 'Oops! 🤖 Spam Alert!',
      description:
        "Your comment got caught in our spam filter! Let's try something better, shall we?",
    },
    spam_short: {
      title: 'Your comment is a little too short',
      description:
        'Could you add a few more words to help others understand your thoughts better?',
    },
    community_guidelines: {
      title: 'Error',
      description: `This comment violates our community guidelines. View the <link href="${ENV.COMMUNITY_GUIDELINES_URL}">community guidelines</link> for more info.`,
    },
    suspended_user: {
      title: 'Account restricted!',
      description: 'Your account has been restricted from commenting.',
    },
  },
  buttons: {
    gotIt: 'Got it',
    cancel: 'Cancel',
    ok: 'OK',
    reply: 'Reply',
    likes: 'Likes',
    boost: 'Boost',
    done: 'Done',
  },
  today_content: {
    more_options: {
      show_me_more_like_this: 'Show me more like this.',
      show_me_less_like_this: 'Show me less like this.',
      this_info_is_incorrect: 'This information is not correct.',
      thank_you_feedback: 'Thank you for the feedback!',
      feedback_message: 'We’ll take this into account going forward.',
      inaccurate_link_more:
        'This information is inaccurate.\nLink more accounts now.',
    },
  },
  comments: {
    search: {
      title: 'Search',
      no_result: 'No results',
    },
    sort_type: {
      newest: 'Newest',
      oldest: 'Oldest',
      most_relevant: 'Most Relevant',
      top_engaged: 'Top Engaged',
    },
    sort_sheet: {
      header: 'Sort by',
      close_cta: 'Cancel',
    },
    more_options: {
      report_comment: 'Report this comment',
      report_user: 'Report this person',
      delete_comment: 'Delete comment',
      dismiss_cta: 'Cancel',
    },
    view_comments: {
      no_comments: 'Be the first to comment...',
      no_comments_comments_locked: 'No comments.',
      error: 'Error loading comments. Please try again later.',
    },
    suspended_user: {
      title: 'Your account has been restricted from commenting.',
    },
    locked: {
      title: 'Comments are locked for this post.',
    },
    report: {
      title_error: 'Error',
      duplicate_comment_error: 'You have already reported this comment.',
      duplicate_user_error: 'You have already reported this person.',
      comment_success: 'Thank you for reporting this comment.',
      user_success: 'Thank you for reporting this person.',
    },
    delete: {
      confirm_modal: {
        title: 'Delete comment?',
        description: 'Are you sure you want to delete this comment?',
        cancel_cta: 'Cancel',
        delete_cta: 'Delete',
      },
      success_snackbar: 'Comment deleted',
    },
    community_guidelines: {
      title: "MoneyLion's <br />Community Guidelines",
      header:
        "We're committed to a fostering positive and respectful community on MoneyLion. Before you post, please agree to our ",
      community_guidelines: 'Community Guidelines',
      rule1: 'Treat everyone with respect',
      rule2: 'Help keep one another safe',
      rule3: 'Follow the law',
      description: ' ',
      footer_start:
        "MoneyLion actively stores and monitors user content in accordance with MoneyLion's ",
      footer_end:
        ' and reports malicious behavior to relevant authorities for the safety of our customers.',
      privacy_policy: 'Privacy Policy',
      read_and_accept: "I've read and accept",
    },
    replies: {
      header: 'Replies',
      reply_cta: 'Reply',
      reply_view: (count: number) => `${count} Replies`,
      no_replies: 'Be the first to reply...',
      no_replies_comments_locked: 'No replies.',
      error: 'Error loading replies. Please try again later.',
      view_replies_count: (count: number) =>
        `--- View ${count} ${count > 1 ? 'Replies' : 'Reply'}`,
    },
  },
}
