export const SOCIAL_MEDIA_API_PATHS = {
  SAVE_REACTION: (contentId: string) =>
    `/discover/v1/reaction/users/contents/${contentId}`,
}

export const TODAY_SEE_MORE_SEE_LESS_EVENTS = {
  TODAY_SEE_MORE_LESS_VIEW: 'today_see_more_less_view',
  TODAY_SEE_MORE_LESS_INTERACT: 'today_see_more_less_interact',
  TODAY_SEE_MORE_LESS_CLOSE: 'today_see_more_less_close',
  TODAY_SEE_LESS_ALL_CARDS: 'today_see_less_all_cards',
}

export const TODAY_SEE_MORE_SEE_LESS_PROPERTY = {
  INTERACTION_SEE_MORE: 'See more',
  INTERACTION_SEE_LESS: 'See less',
}

export const TODAY_HEART_CONTENT_SOURCE = {
  USER_POST: 'USER_POST',
  CONTENTFUL: 'CONTENTFUL',
  NEWS: 'NEWS',
}

export const TODAY_CARD_REACTIONS = {
  HEART: 'HEART',
  UNHEART: 'UNHEART',
}
