import { create } from 'zustand'
import { IconsId, MLDSTheme } from '@moneylion/mlds-web'
import { ActivationState } from '@root/types'
import {
  InsightsTransaction,
  TSpendingCategoryCode,
} from '@root/types/Insights'

type TModalButtonColor = 'primary' | 'secondary' | 'primary-alt' | 'negative'

interface IModalImage {
  src: string
  width: number
  height: number
}

export type TModalButton = {
  label: string
  onClick?: (closeDialog: () => void) => void
  color?: TModalButtonColor
  dataTestId?: string
}

export interface IAppDialogOptions {
  title: string
  subtitle?: string
  text?: string[]
  buttonDirection: 'column' | 'row'
  buttons: TModalButton[]
  dialogSize?: 'Regular' | 'Large'
  image?: IModalImage
  isDismissable?: boolean
  textAlign?: 'text-left' | 'text-center' | 'text-right'
  titleAlign?: 'text-left' | 'text-center' | 'text-right'
  titleSize?: number
  legalAgreement?: {
    docId: string
    docName: string
    onClickAccept?: () => Promise<void>
  }
}

export interface AkoyaLinkOptions {
  bankName: string
  akoyaRedirectUrl: string
}

interface ILegalAgreementDialogOptions {
  title: string
  src: string
  onClickAccept?: () => Promise<void>
}

export interface IWebToAppDialogOptions {
  firstName?: string
  callback?: () => void
  onClickContinueWeb: (closeDialog: () => void) => void
}

interface INotificationModalContent {
  text: React.ReactNode
  icon?: IconsId
  autoHideDuration?: number
}

interface ActivationDownloadAppDialog {
  value: boolean
  version?: ActivationState
}

interface BaseDialogOptions {
  title: string
  categoryCode: TSpendingCategoryCode
  categoryIconColor: keyof MLDSTheme['colors']
  amount: number
  transactions: InsightsTransaction[]
  monthYear: string
}

interface SuccessDialogOptions extends BaseDialogOptions {
  status: 'success'
}

interface ErrorDialogOptions extends BaseDialogOptions {
  status: 'error'
  onClickRetry: () => void
}

interface LoadingDialogOptions extends BaseDialogOptions {
  status: 'loading'
}

export type SpendingTransactionsDialogOptions =
  | SuccessDialogOptions
  | ErrorDialogOptions
  | LoadingDialogOptions

interface UIState {
  showDarkMode: boolean
  showAppDialog: boolean
  showEmailExistsModal: boolean
  showLegalAgreementDialog: boolean
  showAkoyaLinkDialog: boolean
  showLottieLoader: boolean
  showNotificationModal: boolean
  showInstacashEligibleDialog: boolean
  showRoarMoneyBenefitDialog: boolean
  showActivationDownloadAppDialog: ActivationDownloadAppDialog
  showBudgetCalendarDialog: boolean
  showWowBenefitDialog: boolean
  showWebToAppDialog: boolean
  instacashEligibleDialogOnClose?: () => void
  appDialogOptions: IAppDialogOptions | undefined
  akoyaLinkDialogOptions: AkoyaLinkOptions | undefined
  legalAgreementDialogOptions?: ILegalAgreementDialogOptions
  webToAppDialogOptions: IWebToAppDialogOptions | undefined
  notificationModalContent: INotificationModalContent | undefined
  showSpendingTransactionsDialog: boolean
  spendingTransactionsDialogOptions?: SpendingTransactionsDialogOptions
  setShowDarkMode: (value: boolean) => void
  setShowAppDialog: (value: boolean, options?: IAppDialogOptions) => void
  setShowLegalAgreementDialog: (
    value: boolean,
    options?: ILegalAgreementDialogOptions
  ) => void
  setAkoyaLinkDialog: (value: boolean, options?: AkoyaLinkOptions) => void
  setShowLottieLoader: (value: boolean) => void
  setShowNotificationModal: (
    value: boolean,
    content?: INotificationModalContent
  ) => void
  setShowInstacashEligibleDialog: (value: boolean, onClose?: () => void) => void
  setShowRoarMoneyBenefitDialog: (value: boolean) => void
  setShowActivationDownloadAppDialog: (
    props: ActivationDownloadAppDialog
  ) => void
  setShowBudgetCalendarDialog: (value: boolean) => void
  setShowWowBenefitDialog: (value: boolean) => void
  setShowWebToAppDialog: (
    value: boolean,
    options?: IWebToAppDialogOptions
  ) => void
  setShowSpendingTransactionsDialog: (
    value: boolean,
    options?: SpendingTransactionsDialogOptions
  ) => void
}

const useUIStore = create<UIState>()((set) => ({
  showDarkMode: false,
  showAppDialog: false,
  showAkoyaLinkDialog: false,
  showEmailExistsModal: false,
  showLegalAgreementDialog: false,
  showLottieLoader: false,
  showNotificationModal: false,
  showInstacashEligibleDialog: false,
  showRoarMoneyBenefitDialog: false,
  showActivationDownloadAppDialog: {
    value: false,
    version: undefined,
  },
  showBudgetCalendarDialog: false,
  showWowBenefitDialog: false,
  showWebToAppDialog: false,
  appDialogOptions: undefined,
  akoyaLinkDialogOptions: undefined,
  legalAgreementDialogOptions: undefined,
  webToAppDialogOptions: undefined,
  notificationModalContent: undefined,
  showSpendingTransactionsDialog: false,
  spendingTransactionsDialogOptions: undefined,
  setShowDarkMode: (value) => set({ showDarkMode: value }),
  setShowAppDialog: (value, options) => {
    if (options) {
      set({ appDialogOptions: options, showAppDialog: value })
    } else {
      set({ appDialogOptions: undefined, showAppDialog: value })
    }
  },
  setShowLegalAgreementDialog: (value, options) => {
    if (options) {
      set({
        legalAgreementDialogOptions: options,
        showLegalAgreementDialog: value,
      })
    } else {
      set({
        legalAgreementDialogOptions: undefined,
        showLegalAgreementDialog: value,
      })
    }
  },
  setShowLottieLoader: (value) => set({ showLottieLoader: value }),
  setShowNotificationModal: (value, content) => {
    set((state) => ({
      showNotificationModal: value,
      notificationModalContent: content || state.notificationModalContent,
    }))
  },
  setAkoyaLinkDialog(value, options) {
    set({ showAkoyaLinkDialog: value, akoyaLinkDialogOptions: options })
  },
  setShowInstacashEligibleDialog: (value, onClose) => {
    set({
      showInstacashEligibleDialog: value,
      instacashEligibleDialogOnClose: onClose,
    })
  },
  setShowRoarMoneyBenefitDialog: (value) => {
    set({ showRoarMoneyBenefitDialog: value })
  },
  setShowActivationDownloadAppDialog: ({ value, version }) => {
    set({
      showActivationDownloadAppDialog: {
        value,
        version,
      },
    })
  },
  setShowBudgetCalendarDialog: (value) => {
    set({ showBudgetCalendarDialog: value })
  },
  setShowWowBenefitDialog: (value) => {
    set({ showWowBenefitDialog: value })
  },
  setShowWebToAppDialog: (value, options) => {
    set({ showWebToAppDialog: value, webToAppDialogOptions: options })
  },
  setShowSpendingTransactionsDialog: (value, options) => {
    set({
      showSpendingTransactionsDialog: value,
      spendingTransactionsDialogOptions: options,
    })
  },
}))

export { useUIStore }
